.Product_Card {
    position: relative;
}

.Product_Card .p-card {
    padding: 0px;
    border: 1px solid #BEC9C5;
    border-radius: 10px;
    box-shadow: none;
    height: 280px;
}

.Product_Card .p-card .p-card-header {
    padding: 8px 8px 0 8px !important;
    border-radius: 10px;
}

.Product_Card .p-card .p-card-header img {
    border-radius: 10px;
}

.Product_Card .p-card .p-card-body {
    padding: 8px !important;
}

.Product_Card_Btn .p-button {
    margin: 0;
    padding: 4px 6px;
    display: flex;
    justify-content: center;
    justify-items: center;
    height: 30px;
    border-radius: 10px;
    justify-content: end;
}

.Product_Card .p-card .p-card-footer {
    padding: 0;
}

.card_offer_tag {
    position: absolute;
    right: 10px;
    top: -20px;
    background-image: url('../../Assets/Atum_Icons/Offer_tag.svg');
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
}

.center {
    font-size: 10px;
    line-height: 1.5;
    color: white;
    padding-top: 8px;
}

.text_Warp {
    line-height: 1.5rem;
    height: 2.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;

}

.w-280 {
    width: 210px;
}